import React from 'react'
import Layout from "../components/App/Layout"
import PageBannerBackground from '../components/Common/PageBannerBackground' 
import FollowYourHeartContent from '../components/FollowYourHeart/FollowYourHeartContent'
import WhyChooseUs from '../components/FollowYourHeart/WhyChooseUs'

const SocialProjects = () => {
    return (
        <Layout page="Social Projects">
            <PageBannerBackground
                pageTitle="SOCIAL PROJECTS" 
                crumbs={[{page: "Home", url: "/"}, {page: "More"}]}   
                bgFileName="follow-your-heart/follow-your-heart-banner.jpg" 
            />
            <FollowYourHeartContent />
            <WhyChooseUs />
        </Layout>
    );
}

export default SocialProjects