import React from "react"
import {useStaticQuery, graphql, Link} from 'gatsby'
import VideoResponsivePlayer from "../App/VideoResponsivePlayer"
import starIcon from "../../assets/images/star-icon.png"

const ExploreEmbraceChild = () => {

  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        videosBaseUrl
      }
    }
  }
`)

  return (
    <React.Fragment>
      <div className="explore-learning-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="explore-learning-content">
                <span className="sub-title">
                  <img src={starIcon} alt="team" />
                  Sponsorship
                </span>
                <h2>Embrace a Child</h2>
                <p>
                We believe that every child, no matter where they were born or their circumstance, deserves the opportunity to determine their own story.<br/>
                That’s where wellbeing comes in. Wellbeing is about connection, teaching the next generation the importance of diversity connection.
                </p>
                <Link to="/contact" className="default-btn" state={{page: "sponsorship"}}>
                  <i className="flaticon-right"></i> Sponsor Now <span></span>
                </Link>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="explore-learning-video">
                <VideoResponsivePlayer
                  url={data.site.siteMetadata.videosBaseUrl + '/embrace_a_child.mp4'}
                  light={data.site.siteMetadata.videosBaseUrl + '/embrace_a_child.png'}
                  controls={true}
                  playing={true}
                  loop={false}
                  muted={false}
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ExploreEmbraceChild
