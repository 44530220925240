import React from "react"
import { Link } from "gatsby"
import starIcon from "../../assets/images/star-icon.png"
import { StaticImage } from "gatsby-plugin-image"
import ExploreEmbraceChild from "./ExploreEmbraceChild"

const FollowYourHeartContent = () => {
  return (
    <div className="events-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="What do you deeply desire?" />
            What do you deeply desire?
          </span>
          <h2>
            Our goal is to teach, inspire and develop a "chain of good" culture
            with diversity, connection, purpose and love. </h2> 
            <p>When we have something – a talent or gift – that gives us an endless flow of creativity, sense of peace, freedom or empowerment, it serves as a clear sign that others could benefit from it too. When we decide to share, we support the potential for the endless opportunities our gift affords others.<br/>
            We can transform everything. We are stronger together.</p>
        </div>

        <ExploreEmbraceChild />

        <div className="row">
          <div className="col-lg-6 col-sm-6 col-md-6">
            <div className="single-events-box">
              <div className="image">
              <Link to="/contact" state={{page: "follow-your-heart"}} className="d-block">
                  <StaticImage
                    src="../../assets/images/follow-your-heart/shine_on_me.jpg"
                    placeholder="blurred"
                    alt="Shine On Me"
                  />
                </Link>
              </div>

              <div className="content">
                <h3>
                <Link to="/contact" state={{page: "follow-your-heart"}}>Shine on Me</Link>
                </h3>
                <p>
                Guidance for all Journey - Free assessment<br/>
                If you need  guidance to find your place in the world, talk to one of our lovely  Instructors.<br/> 
                A session with a certified Therapist to develop a personal map of wellbeing and therapy packages will help you.
                </p>
                <Link to="/contact" className="default-btn" state={{page: "follow-your-heart"}}>
                  <i className="flaticon-right"></i> Shine Now <span></span>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-sm-6 col-md-6">
            <div className="single-events-box">
              <div className="image">
              <Link to="/contact" state={{page: "follow-your-heart"}} className="d-block">
                  <StaticImage
                    src="../../assets/images/follow-your-heart/lovely_family.jpg"
                    placeholder="blurred"
                    alt="Loving Cozy Family - Belong"
                  />
                </Link>
              </div>

              <div className="content">
                <h3>
                <Link to="/contact" state={{page: "follow-your-heart"}}>Loving Cozy Family - Belong</Link>
                </h3>
                <p>
                Start your connection journey<br/> 
                Join our community today to maintain and nurture good mental health and wellbeing.<br/>
                We are here to support and inspire.<br/>
                Build authentic connections with others like you.
                </p>
                <Link to="/contact" className="default-btn" state={{page: "follow-your-heart"}}>
                  <i className="flaticon-right"></i> Connect Now <span></span>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-sm-6 col-md-6">
            <div className="single-events-box">
              <div className="image">
              <Link to="/contact" state={{page: "sponsorship"}} className="d-block">
                  <StaticImage
                    src="../../assets/images/follow-your-heart/embrace_women.jpg"
                    placeholder="blurred"
                    alt="Embrace a Women"
                  />
                </Link>
              </div>

              <div className="content">
                <h3>
                <Link to="/contact" state={{page: "sponsorship"}}>Embrace a Woman</Link>
                </h3>
                <p>
                Sponsor women<br/>
                A powerful project to support people worldwide; teaching, supporting, and bringing together as a family.<br/>
Teaching personal, professional, and social skills to wellbeing connection, professional opportunities, and diversity inclusion.<br/>
We are all together. We are stronger together.<br/>
“Learn to light a candle in the darkest moments of someone’s life. Be the light that helps others see; it is what gives life its deepest significance.” ― Roy T. Bennett, The Light in the Heart.
                </p>
                <Link to="/contact" className="default-btn" state={{page: "sponsorship"}}>
                  <i className="flaticon-right"></i> Embrace Now <span></span>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-sm-6 col-md-6">
            <div className="single-events-box">
              <div className="image">
                <Link to="/contact" state={{page: "sponsorship"}} className="d-block">
                  <StaticImage
                    src="../../assets/images/follow-your-heart/embrace_child.jpg"
                    placeholder="blurred"
                    alt="Embrace a Child"
                  />
                </Link>
              </div>

              <div className="content">
                <h3>
                  <Link to="/contact" state={{page: "sponsorship"}}>Embrace a Child</Link>
                </h3>
                <p>
                Sponsor children<br/>
                We believe that every child, no matter where they were born or what their circumstance, deserves the opportunity to determine their own story.<br/>
                That’s where wellbeing comes in. Wellbeing is about connection. 
                Teaching the next generation the importance of diversity connection.
                </p>
                <Link to="/contact" className="default-btn" state={{page: "sponsorship"}}>
                  <i className="flaticon-right"></i> Embrace Now <span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FollowYourHeartContent
