import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-50">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="choose" /> 
                                How We’re Different
                            </span>
                            <h2>We believe that everyone, no matter where they were born or their circumstance, deserves the opportunity to determine their own story.</h2>
                            <p>That’s where wellbeing support comes in. Wellbeing is about connection. Where poverty, mental health issues, loneliness robs us of opportunities, wellbeing connection gives them back. Connection opens doors and brings us together as one.<br/>
                            We have a vision of the world transformed through diversity connection: a future in which everyone learns and help each other to grow, connect and change the world together in a better place for them and the next generation. But is it possible?<br/>
                            Absolutely. Because we know that when we empower people with knowledge, inspiration, and connection, they will not only determine their own stories but also shape the destiny of their world together.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Energy Focus</h3>
                                    <p>We work in holistic wellness. That’s it. And that’s how we became experts in the field.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Community Engagement</h3>
                                    <p>We partner with teachers, psychologists, therapists, nutritionists, physioterapists, parents, and so that everyone has a hand in forging new opportunities for all.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Attacking the Root</h3>
                                    <p>We target the root causes by giving high-quality tailored training, courses, and opportunities, so they can develop their full potential.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        4
                                    </div>
                                    <h3>Proven Projects</h3>
                                    <p>We encourage you to connect with your sponsored to be more than a sponsor, become a huge family connected with purpose.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <StaticImage
                                src="../../assets/images/follow-your-heart/embrace_child2.jpg"
                                placeholder="blurred"
                                alt="Corporate Mother"
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="how-its-work-content">
                            <p>Ready to Break the Cycle of Poverty, mental health issues, and loneliness through wellbeing connection?</p>
                                <Link to="/contact" className="default-btn" state={{page: "sponsorship"}}>
                                    <i className="flaticon-right"></i> Follow Your Heart <span></span>
                                </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs